import React, { useState, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/autoplay";
import { Autoplay } from "swiper/modules";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Container } from "@mui/material";
import SectionTitle from "../core/SectionTitle";
import DealsOfTheDayCard from "./DealsOfTheDayCard";
import { useSelector } from "react-redux";

const baseCloudinaryURL = process.env.REACT_APP_CLOUDINARY_BASE_URL;
const bgImageURL = `${baseCloudinaryURL}/v1732274381/vegingo/Untitled_design_22_ncorll.png`;

export default function DealsOfTheDay() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // will change it with deals of the day api later
  const { items: products} = useSelector(
    (state) => state.products
  );
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef(null); 

  const handleSlideChange = (swiper) => {
    setActiveIndex(swiper.activeIndex); 
  };

  const handleDotClick = (index) => {
    setActiveIndex(index); 
    if (swiperRef.current) {
      swiperRef.current.slideTo(index); 
    }
  };

  return (
    <Box
      sx={{
        background: "background.paper",
        background: `url(${bgImageURL})`,
        backgroundPosition: "bottom",
        backgroundSize: "cover",
        py: 2,
        mt: 10,
      }}
    >
      <Container maxWidth="lg">
        <SectionTitle t1={"Deals Of The Day"} />
        <Box sx={{ position: "relative", mt: 2, pb: 4 }}>
          <Swiper
            autoplay={{ delay: 3000 }}
            slidesPerView={isSmallScreen ? 1 : 2}
            spaceBetween={40}
            onSlideChange={handleSlideChange}
            modules={[Autoplay]}
            className="mySwiper3"
            style={{
              zIndex: 10,
              height:  isSmallScreen ? "auto" :"250px"
            }}
            onSwiper={(swiper) => (swiperRef.current = swiper)}
          >
            {products.slice(0, 5).map((product) => (
              <SwiperSlide style={{ height: "100%",zIndex:1}} key={product.id}>
                <DealsOfTheDayCard productData={product} />
              </SwiperSlide>
            ))}
          </Swiper>

          {/* Custom Pagination */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
              gap: 1,
            }}
          >
            {products.slice(0, 4).map((_, index) => (
              <Box
                key={index}
                onClick={() => handleDotClick(index)}
                sx={{
                  width: "12px",
                  height: "12px",
                  borderRadius: "50%",
                  backgroundColor:
                    index === activeIndex
                      ? theme.palette.primary.main
                      : theme.palette.grey[400],
                  cursor: "pointer",
                  transition: "background-color 0.3s",
                }}
              />
            ))}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
