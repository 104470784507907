import React, { useState } from 'react';
import { Button, Typography, Box, Paper, CircularProgress, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Payment = () => {
    const { billDetails } = useSelector((state) => state.order);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('PhonePe');

    const amount = billDetails?.grandTotal || 0;

    
    const handlePlaceOrder = () => {
        if (paymentMethod === 'COD') {
            toast.success('Order confirmed successfully');
            navigate('/order-confirmed');
        } else {
            toast.error('Invalid action for selected payment method.');
        }
    };

    
    const initiatePhonePePayment = async () => {
        if (!amount || amount <= 0) {
            toast.error('Invalid payment amount.');
            return;
        }

        setIsLoading(true);

        try {
            const response = await axios.post('https://panel.vegingo.com/api/v1/phonepe', {
                amount,
            });

            if (response.status === 200 && response.data?.url) {
                window.location.href = response.data.url;
            } else {
                toast.error(response.data?.message || 'Failed to create payment.');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            toast.error(error.response?.data?.message || 'Error initiating payment');
        } finally {
            setIsLoading(false);
        }
    };

    const handlePayment = () => {
        if (paymentMethod === 'PhonePe') {
            initiatePhonePePayment();
        } else if (paymentMethod === 'COD') {
            handlePlaceOrder();
        } else {
            toast.error('Please select a valid payment method.');
        }
    };

    return (
        <Box sx={{ minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Paper sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
                <Typography variant="h5" sx={{ mb: 2 }}>Select Payment Method</Typography>

                <RadioGroup
                    value={paymentMethod}
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    sx={{ mb: 2 }}
                >
                    <FormControlLabel value="PhonePe" control={<Radio />} label="Pay with PhonePe" />
                    <FormControlLabel value="COD" control={<Radio />} label="Cash on Delivery (COD)" />
                </RadioGroup>

                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={handlePayment}
                    disabled={isLoading}
                    sx={{ mb: 2 }}
                >
                    {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Proceed to Pay'}
                </Button>
            </Paper>
        </Box>
    );
};

export default Payment;
