import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Chip,
  LinearProgress,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom"; 

const DealsOfTheDayCard = ({ productData }) => {
  const [timeLeft, setTimeLeft] = useState({
    hours: 12,
    minutes: 10,
    seconds: 22,
  });

  const navigate = useNavigate(); 
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const discountPercentage = Math.round(
    ((productData.price - productData.discounted_price) / productData.price) * 100
  );

  // Countdown logic
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        let { hours, minutes, seconds } = prev;
        if (seconds > 0) seconds--;
        else if (minutes > 0) {
          minutes--;
          seconds = 59;
        } else if (hours > 0) {
          hours--;
          minutes = 59;
          seconds = 59;
        } else {
          clearInterval(timer);
        }
        return { hours, minutes, seconds };
      });
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  
  const handleCardClick = () => {
    navigate(`/product/${productData.name}`); 
  };

  return (
    <Card
      onClick={handleCardClick} 
      sx={{
        border: "1px solid #f44336",
        borderRadius: "8px",
        maxWidth: isSmallScreen ? 350 : 600, 
        cursor: "pointer",
        display: "flex",
        flexDirection: isSmallScreen ? "column" : "row",
        padding: 2,
        gap: 2,
        transition: "transform 0.2s ease-in-out",
        "&:hover": {
          transform: "scale(1.02)",
        },
      }}
    >
      {/* Product Image with Discount Badge */}
      <Box
        sx={{
          position: "relative",
          flex: "1 1 40%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="img"
          src={productData.image || "https://via.placeholder.com/150"}
          alt="Product"
          sx={{
            width: "100%",
            borderRadius: 1,
            objectFit: "cover",
            height: isSmallScreen ? 150 : 200, 
          }}
        />
        <Chip
          label={`${discountPercentage|| "0"}% OFF`}
          sx={{
            position: "absolute",
            top: -5,
            left: 10,
            borderRadius: "50%",
            width: 80,
            height: 80,
            border:2,
            borderColor:"white",
            backgroundColor: "#f44336",
            color: "#fff",
            fontSize: "14px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        />
      </Box>

      {/* Product Details */}
      <CardContent
        sx={{
          flex: "1 1 60%",
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p:0
        }}
      >
        {/* Pricing */}
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#f44336" }}>
          ₹{productData.discounted_price}{" "}
          <Typography
            component="span"
            sx={{
              textDecoration: "line-through",
              fontSize: "14px",
              color: "#888",
              marginLeft: "8px",
            }}
          >
            ₹{productData.price}
          </Typography>
        </Typography>

        {/* Product Name and Stock Status */}
        <Typography variant="body1" fontWeight="bold">
          {productData.name}
        </Typography>
        <Typography variant="body2" color="green">
          {productData.stock_quantity} kg IN STOCK
        </Typography>

        {/* Progress Bar */}
        <LinearProgress
          variant="determinate"
          value={70} 
          sx={{
            height: 8,
            borderRadius: 4,
            backgroundColor: "#ffcdd2",
            "& .MuiLinearProgress-bar": {
              background: "linear-gradient(to right, red, yellow)",
            },
          }}
        />

        {/* Countdown Timer */}
        <Stack direction="row" spacing={1} alignItems="center">
          <Box sx={{ display: "flex", gap: 1 }}>
            {[timeLeft.hours, timeLeft.minutes, timeLeft.seconds].map(
              (time, index) => (
                <React.Fragment key={index}>
                  <Box
                    sx={{
                      backgroundColor: "#f5f5f5",
                      padding: "4px 8px",
                      borderRadius: 2,
                      textAlign: "center",
                    }}
                  >
                    <Typography variant="h6">
                      {String(time).padStart(2, "0")}
                    </Typography>
                  </Box>
                  {index < 2 && (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        fontWeight: "bold",
                      }}
                    >
                      :
                    </Box>
                  )}
                </React.Fragment>
              )
            )}
          </Box>
          <Typography variant="body2" sx={{ color: "#666" }}>
            Remains until the end of the offer
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default DealsOfTheDayCard;
