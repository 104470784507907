import React from 'react';
import { Grid, Card, Typography, Box, Container, useTheme, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import SectionTitle from '../core/SectionTitle';

// Sample data for categories
const categories = [
  { name: "Salad Veggies", items: 125, image: "/salad-bowl.webp", bgColor: "#A8D08D", link: "/shop/salad veggies" },
  { name: "Stir-Fry Veggies", items: 65, image: "/stir-fry.png", bgColor: "#FFD966", link: "/shop/stir-fry veggies" },
  { name: "Indian Cooking", items: 60, image: "/indian-food.png", bgColor: "#C6E0B4", link: "/shop/indian" },
  { name: "Chinese Cooking", items: 420, image: "/chinese-food.webp", bgColor: "#F4B183", link: "/shop/chinese" },
  { name: "Soup Veggies", items: 83, image: "/soup.png", bgColor: "#FFE599", link: "/shop/soup veggies" },
];

const CategoryCard = ({ name, items, image, bgColor, link }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Link to={link} style={{ textDecoration: 'none' }}>
      <Card
        style={{
          padding: isSmallScreen ? 8 : 16, 
          textAlign: 'center',
          boxShadow: 'none',
        }}
      >
        <Box
          sx={{
            width: isSmallScreen ? 100 : 150, 
            height: isSmallScreen ? 100 : 150,
            backgroundColor: bgColor,
            borderRadius: '50%',
            position: 'relative',
            margin: '0 auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover img': {
              transform: 'scale(1.3) rotate(5deg)',
              filter: 'drop-shadow(0 4px 6px rgba(0, 0, 0, 0.2))',
            },
          }}
        >
          <Box
            component="img"
            src={image}
            alt={name}
            sx={{
              width: isSmallScreen ? 80 : 150, 
              height: isSmallScreen ? 80 : 150,
              position: 'absolute',
              transition: 'transform 0.3s ease, filter 0.3s ease',
            }}
          />
        </Box>
        <Typography
          variant="body1"
          sx={{ mt: 1,fontWeight:500, color: 'inherit'}}
        >
          {name}
        </Typography>
        {/* <Typography
          variant="body2"
          color="textSecondary"
          style={{ fontSize: isSmallScreen ? '0.8rem' : '1rem' }}
        >
          {items} items
        </Typography> */}
      </Card>
    </Link>
  );
};

const PopularCategoryFilter = () => {
  return (
    <Container maxWidth="lg">
      <SectionTitle t1="Popular Categories" t2="Explore Our Top Picks" />

      <Grid container spacing={2} justifyContent="center">
        {categories.map((category, index) => (
          <Grid item xs={6} sm={4} md={2} key={index}>
            <CategoryCard {...category} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PopularCategoryFilter;
