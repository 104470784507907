import axios from "axios";
import api from "../apiCollection";

export const fetchProducts = async (page = 1, limit = 10) => {
    try {
        const response = await axios.get(api.products.getAll, { params: { page, limit } });
        console.log('API Response:', response.data);
        return response.data.data.products;
    } catch (error) {
        console.error('Error fetching products:', error.message);
        return [];
    }
};
