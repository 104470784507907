import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchProducts } from '../../services/productService';

export const loadProducts = createAsyncThunk(
    'products/loadProducts',
    async ({ page, limit }) => {
        const data = await fetchProducts(page, limit);
        return data;
    }
);

const productSlice = createSlice({
    name: 'products',
    initialState: {
        items: [],
        page: 1,
        hasMore: true,
        status: 'idle',
        error: null,
    },
    reducers: {
        resetProducts: (state) => {
            state.items = [];
            state.page = 1;
            state.hasMore = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loadProducts.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(loadProducts.fulfilled, (state, action) => {
                state.status = 'succeeded';
                if (action.payload.length === 0) {
                    state.hasMore = false; // No more pages
                } else {
                    state.items = [...state.items, ...action.payload];
                    state.page += 1; // Increment the page for the next fetch
                }
            })
            .addCase(loadProducts.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export const { resetProducts } = productSlice.actions;
export default productSlice.reducer;
