import React, { useState } from "react";
import {
  Slider,
  Checkbox,
  FormControlLabel,
  Typography,
  FormGroup,
  Divider,
  useTheme,
  Button,
  Box,
} from "@mui/material";


const Filters = ({ onApplyFilters }) => {
  const [priceRange, setPriceRange] = useState([0, 100]);
  const [inStock, setInStock] = useState(false);
  const [onSale, setOnSale] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState([]);
  

  const brands = ["Farm Fresh", "Organic Valley", "Local Harvest"];

  const handlePriceChange = (event, newValue) => {
    setPriceRange(newValue);
  };

  const handleBrandChange = (brand) => {
    if (selectedBrands.includes(brand)) {
      setSelectedBrands(selectedBrands.filter((b) => b !== brand));
    } else {
      setSelectedBrands([...selectedBrands, brand]);
    }
  };

  const applyFilters = () => {
    onApplyFilters({
      priceRange,
      inStock,
      onSale,
      selectedBrands,
    });
  };

  return (
    <Box sx={{p:2}}>
      <Typography variant="h7" fontWeight="bold">Filter by Price</Typography>
      <Slider
        value={priceRange}
        onChange={handlePriceChange}
        valueLabelDisplay="auto"
        min={0}
        max={100}
        step={5}
      />
      <Typography>
        Price: ₹{priceRange[0]} — ₹{priceRange[1]}
      </Typography>
      <Divider sx={{my:2}}/>
      <Typography variant="h7" fontWeight="bold" style={{ marginTop: "16px" }}>
        Product Status
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={inStock}
              onChange={(e) => setInStock(e.target.checked)}
            />
          }
          label="In Stock"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={onSale}
              onChange={(e) => setOnSale(e.target.checked)}
            />
          }
          label="On Sale"
        />
      </FormGroup>

      <Divider sx={{my:2}}/>

      <Typography variant="h7" fontWeight="bold" style={{ marginTop: "16px" }}>
        Brands
      </Typography>
      <FormGroup>
        {brands.map((brand) => (
          <FormControlLabel
            key={brand}
            control={
              <Checkbox
                checked={selectedBrands.includes(brand)}
                onChange={() => handleBrandChange(brand)}
              />
            }
            label={brand}
          />
        ))}
      </FormGroup>

      <Button
      variant="contained"
      color="primary"
      size="small"
        onClick={applyFilters}
        sx={{
          mt: 2,  
          color:"white"     
        }}
      >
        Apply Filters
      </Button>
    </Box>
  );
};

export default Filters;


