import React from 'react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import Container from '@mui/material/Container';
import { useTheme } from "@mui/material/styles";
import SectionTitle from '../core/SectionTitle';
import CustomSlider from './CustomSlider';
import ProductCard from '../product/ProductCard';


export default function SpecialProducts({ specialProducts }) {
  const theme = useTheme();

  
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <SectionTitle t1={"Special Products"} t2={"Featured and Exclusive Finds"} />

      <CustomSlider
        items={specialProducts}
        renderSlide={(item) => <ProductCard product={item}/>}
        maxSlides={10}
        navigationClass="special-product"
      />
    </Container>
  );
}
