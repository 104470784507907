import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Backdrop from '@mui/material/Backdrop';
import {
    Radio,
    RadioGroup,
    FormControlLabel,
} from '@mui/material';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import DiscountIcon from '@mui/icons-material/Discount';
import { createOrderFromCart } from '../redux/slices/orderSlice';

export default function Payment() {
    const dispatch = useDispatch();

    const {
        billDetails,
        selectedAddress,
        orderNote,
        selectedCoupon,
    } = useSelector((state) => state.order);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState('PhonePe');
    const [isLoadingOverlay, setIsLoadingOverlay] = useState(false);

    const amount = billDetails?.grandTotal || 0;

    const handlePayment = async () => {
        if (!['PhonePe', 'COD'].includes(paymentMethod)) {
            toast.error('Please select a valid payment method.');
            return;
        }

        try {
            setIsLoadingOverlay(true); // Show loading overlay

            // Dispatch the place order action and wait for it to fulfill
            await dispatch(
                createOrderFromCart({
                    addressId: selectedAddress.id,
                    orderNote,
                    selectedCoupon,
                    deliveryCharge: billDetails.deliveryCharge,
                    paymentMethod,
                })
            ).unwrap(); // `unwrap` ensures the promise throws if the action is rejected

            // Proceed with payment based on the selected method
            if (paymentMethod === 'PhonePe') {
                await initiatePhonePePayment(); // Call payment initiation only after order is placed
            } else if (paymentMethod === 'COD') {
                navigate("/order-confirmed", { replace: true }); // Navigate to confirmation page for COD
            }
        } catch (error) {
            // Handle errors from the order creation
            console.error('Error placing order:', error);
            toast.error('Failed to place order. Please try again.');
        } finally {
            setIsLoadingOverlay(false); // Hide loading overlay once the process is finished
        }
    };

    const initiatePhonePePayment = async () => {
        if (!amount || amount <= 0) {
            toast.error('Invalid payment amount.');
            return;
        }

        try {
            setIsLoadingOverlay(true); // Show loading overlay

            const response = await axios.post('https://panel.vegingo.com/api/v1/phonepe', {
                amount,
            });

            if (response.status === 200 && response.data?.url) {
                window.location.href = response.data.url;
            } else {
                toast.error(response.data?.message || 'Failed to create payment.');
            }
        } catch (error) {
            console.error('Error initiating payment:', error);
            toast.error(error.response?.data?.message || 'Error initiating payment');
        } finally {
            setIsLoadingOverlay(false); // Hide loading overlay once the payment initiation is finished
        }
    };

    return (
        <Box sx={{ minHeight: '100vh' }}>
            <Container maxWidth="lg" sx={{ my: 5 }}>
                <Grid container columnSpacing={5} rowGap={5}>
                    <Grid item md={6} xs={12}>
                        <Paper sx={{ padding: 2, width: '100%' }}>
                            <Typography variant="h6" sx={{ mb: 2, fontWeight: 500 }}>Select Payment Method</Typography>

                            <RadioGroup
                                value={paymentMethod}
                                onChange={(e) => setPaymentMethod(e.target.value)}
                                sx={{ mb: 2 }}
                            >
                                <FormControlLabel value="PhonePe" control={<Radio />} label="Pay with PhonePe" />
                                <FormControlLabel value="COD" control={<Radio />} label="Cash on Delivery (COD)" />
                            </RadioGroup>

                            <Button
                                variant="contained"
                                color="primary"
                                size='small'
                                fullWidth
                                onClick={handlePayment}
                                disabled={isLoading}
                                sx={{ mb: 2, color: "white" }}
                            >
                                {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Proceed to Pay'}
                            </Button>
                        </Paper>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Paper
                            elevation={2}
                            sx={{ p: 2, position: 'sticky', top: 20 }}
                        >
                            <Box sx={{ py: 2 }}>
                                <Typography
                                    variant="body2"
                                    gutterBottom
                                    sx={{
                                        fontWeight: 800,
                                        textTransform: 'uppercase',
                                    }}
                                >
                                    Bill details
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <ReceiptIcon
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Typography variant="body2">
                                            Sub Total
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        ₹ {billDetails.cartTotal}
                                    </Typography>
                                </Box>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            gap: 1,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <DeliveryDiningIcon
                                            sx={{ fontSize: '1rem' }}
                                        />
                                        <Typography variant="body2">
                                            Delivery Charge
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        {billDetails.deliveryCharge === 0
                                            ? 'FREE'
                                            : `- ₹ ${billDetails.deliveryCharge}`}
                                    </Typography>
                                </Box>

                                {billDetails.discount > 0 && (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: 1,
                                                alignItems: 'center',
                                            }}
                                        >
                                            <DiscountIcon
                                                sx={{ fontSize: '1rem' }}
                                            />
                                            <Typography variant="body2">
                                                Coupon Discount
                                            </Typography>
                                        </Box>
                                        <Typography
                                            variant="body2"
                                            color="primary"
                                        >
                                            - ₹ {billDetails.discount}
                                        </Typography>
                                    </Box>
                                )}

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Typography variant="subtitle2">
                                        Grand Total
                                    </Typography>
                                    <Typography variant="subtitle2">
                                        ₹ {billDetails.grandTotal}
                                    </Typography>
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>

            {/* Loading Overlay */}
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={isLoadingOverlay}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    );
}
