import React from 'react';
import { Box, Typography, Container, Grid, Card, CardContent, Avatar, CardActionArea, CardActions, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Facebook, Twitter, Instagram, LinkedIn } from '@mui/icons-material';
import SectionTitle from '../components/core/SectionTitle';

const teamMembers = [
  { name: 'John Doe', role: 'CEO', image: 'https://via.placeholder.com/150', bio: 'John is the visionary behind VeginGo, driving innovation and growth.', social: { facebook: '#', twitter: '#', instagram: '#', linkedin: '#' } },
  { name: 'Jane Smith', role: 'CTO', image: 'https://via.placeholder.com/150', bio: 'Jane leads the tech team to build the platform that powers VeginGo.', social: { facebook: '#', twitter: '#', instagram: '#', linkedin: '#' } },
  { name: 'Michael Johnson', role: 'COO', image: 'https://via.placeholder.com/150', bio: 'Michael oversees operations, ensuring timely deliveries and quality service.', social: { facebook: '#', twitter: '#', instagram: '#', linkedin: '#' } },
  { name: 'Emily Davis', role: 'Marketing Head', image: 'https://via.placeholder.com/150', bio: 'Emily is responsible for spreading the word and creating brand awareness.', social: { facebook: '#', twitter: '#', instagram: '#', linkedin: '#' } },
];

const AboutUs = () => {
  const theme = useTheme();

  return (
    <Box>
      {/* Parallax Section */}
      <Box
        sx={{
          position: 'relative',
          height: '400px',
          backgroundImage: 'url("https://res.cloudinary.com/dbi8wjrcl/image/upload/q_auto:low/v1729788948/vegingo/pexels-fauxels-3183190_fua0sa.jpg")', // Replace with your image URL
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: 'white',
            fontWeight: 'bold',
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
          }}
        >
          ABOUT US
        </Typography>
      </Box>

      {/* Content Section */}
      <Container sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Welcome to VeginGo!
        </Typography>
        <Typography paragraph>
          VeginGo is a leading e-commerce platform specializing in delivering fresh vegetables directly to your doorstep. Our mission is to make healthy eating more accessible, convenient, and affordable. We source our products from local farms, ensuring the freshest and highest-quality produce for our customers. With easy online ordering and fast delivery, we make it simple for you to enjoy fresh vegetables, no matter where you are.
        </Typography>
        <Typography paragraph>
          Our team is committed to providing excellent customer service, and we strive to make every shopping experience as seamless as possible. Join us on our journey to healthier living and better eating!
        </Typography>
      </Container>

      {/* Meet Our Team Section */}
      <Box sx={{ bgcolor: theme.palette.background.paper, py: 6 }}>
        <Container>
          <SectionTitle t1={"Meet Our Team"}/>
          <Grid container spacing={4} justifyContent="center">
            {teamMembers.map((member, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    position: 'relative',
                    '&:hover .overlay': {
                      opacity: 1,
                    },
                    paddingBottom: 4, 
                  }}
                >

                  <Avatar
                    alt={member.name}
                    src={member.image}
                    sx={{
                      width: 120,
                      height: 120,
                      border: `5px solid ${theme.palette.background.paper}`,
                      position: 'relative',

                    }}
                  />

                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {member.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {member.role}
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                      {member.bio}
                    </Typography>
                  </CardContent>


                  {/* Hover Effect Overlay */}
                  <Box
                    className="overlay"
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'rgba(0, 0, 0, 0.7)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out',
                      zIndex: 2, 
                    }}
                  >
                    <Box sx={{ display: 'flex', gap: 2 }}>
                      {member.social.facebook && (
                        <IconButton
                          href={member.social.facebook}
                          target="_blank"
                          sx={{
                            bgcolor: theme.palette.primary.main, 
                            '&:hover': {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          <Facebook style={{color:"white"}} />
                        </IconButton>
                      )}
                      {member.social.twitter && (
                        <IconButton
                          href={member.social.twitter}
                          target="_blank"
                          sx={{
                            bgcolor: theme.palette.primary.main, 
                            '&:hover': {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          <Twitter style={{color:"white"}} />
                        </IconButton>
                      )}
                      {member.social.instagram && (
                        <IconButton
                          href={member.social.instagram}
                          target="_blank"
                          sx={{
                            bgcolor: theme.palette.primary.main, 
                            '&:hover': {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          <Instagram style={{color:"white"}} />
                        </IconButton>
                      )}
                      {member.social.linkedin && (
                        <IconButton
                          href={member.social.linkedin}
                          target="_blank"
                          sx={{
                            bgcolor: theme.palette.primary.main, 
                            '&:hover': {
                              bgcolor: theme.palette.primary.dark,
                            },
                          }}
                        >
                          <LinkedIn style={{color:"white"}} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AboutUs;
