import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import PageTitle from '../components/core/PageTitle';
import { persistor } from '../redux/store';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import AddressList from '../components/account/AddressList';
import MyProfile from '../components/account/MyProfile';
import { fetchAddress } from '../redux/slices/addressSlice';
import { fetchOrderHistory } from '../redux/slices/orderSlice';
import OrderList from '../components/account/OrderList';
import Loading from '../components/core/Loading';
import CancelIcon from '@mui/icons-material/Cancel';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountPrivacy from '../components/account/AccountPrivacy';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            className="scroll"
            style={{ width: '100%', overflow: 'auto' }}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    sx={{ p: { xs: 1, sm: 3 }, height: '100%', width: '100%' }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function Account() {
    const { tabName } = useParams();
    const [value, setValue] = useState(0);
    const [open, setOpen] = useState(false); // State to manage the confirmation modal
    const isSmallScreen = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const tabMapping = {
        profile: 0,
        address: 1,
        orders: 2,
        wallet: 3,
        privacy: 4,
    };

    useEffect(() => {
        if (tabName) {
            setValue(tabMapping[tabName] || 0);
        }
    }, [tabName]);

    const handleLogout = () => {
        setOpen(true); // Open the confirmation modal
    };

    const handleConfirmLogout = async () => {
        await dispatch(logout());
        persistor.purge();
        navigate('/account/login');
    };

    const handleClose = () => {
        setOpen(false); // Close the confirmation modal
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        const tabName = Object.keys(tabMapping).find(
            (key) => tabMapping[key] === newValue
        );
        navigate(`/account/${tabName}`);
    };

    const order = useSelector((state) => state.order);
    const address = useSelector((state) => state.address);

    useEffect(() => {
        dispatch(fetchAddress());
    }, [dispatch]);

    useEffect(() => {
        if (address.status === 'succeeded' && address.addresses.length > 0) {
            dispatch(fetchOrderHistory());
        }
    }, [address.status, dispatch]);

    if (address.status === 'loading' || order.status === 'loading') {
        return <Loading />;
    }

    return (
        <Box maxWidth={'100%'}>
            <Container maxWidth="lg" sx={{ my: 4 }}>
                <Paper>
                    <Box
                        sx={{
                            flexGrow: 1,
                            bgcolor: 'Background.paper',
                            display: 'flex',
                            flexDirection: isSmallScreen ? 'column' : 'row',
                            height: '100%',
                            minHeight: isSmallScreen ? '600px' : '800px',
                        }}
                    >
                        <Box
                            display="flex"
                            flexDirection={
                                isSmallScreen ? 'column-reverse' : 'column'
                            }
                        >
                            <Tabs
                                orientation={
                                    isSmallScreen ? 'horizontal' : 'vertical'
                                }
                                variant={
                                    isSmallScreen ? 'scrollable' : 'standard'
                                }
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                                value={value}
                                onChange={handleChange}
                                aria-label="Vertical tabs example"
                                sx={{ borderColor: 'divider' }}
                            >
                                <Tab label="My Profile" {...a11yProps(0)} />
                                <Tab label="My Addresses" {...a11yProps(1)} />
                                <Tab label="My Orders" {...a11yProps(2)} />
                                <Tab label="My Wallet" {...a11yProps(3)} />
                                <Tab
                                    label="Account Privacy"
                                    {...a11yProps(4)}
                                />
                            </Tabs>

                            {!isSmallScreen && (
                                <Box
                                    sx={{
                                        mt: 4,
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Button
                                        startIcon={<LogoutOutlinedIcon />}
                                        onClick={handleLogout}
                                        variant="contained"
                                        color="primary"
                                        sx={{ color: 'white' }}
                                    >
                                        Logout
                                    </Button>
                                </Box>
                            )}
                        </Box>
                        <Divider
                            orientation={
                                isSmallScreen ? 'horizontal' : 'vertical'
                            }
                            flexItem
                        />

                        <TabPanel value={value} index={0}>
                            <MyProfile />
                        </TabPanel>

                        <TabPanel value={value} index={1}>
                            <AddressList />
                        </TabPanel>

                        <TabPanel value={value} index={2}>
                            <OrderList />
                        </TabPanel>

                        <TabPanel value={value} index={3}>
                            Wallet Feature is Coming Soon . Stay tuned !
                        </TabPanel>

                        <TabPanel value={value} index={4}>
                            <AccountPrivacy />
                        </TabPanel>
                    </Box>
                </Paper>
            </Container>

            {/* Logout Confirmation Modal */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="logout-dialog-title"
                aria-describedby="logout-dialog-description"
            >
                <DialogTitle id="logout-dialog-title">
                    {'Confirm Logout'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="logout-dialog-description">
                        Are you sure you want to logout?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                    <Button
                        endIcon={<CancelIcon />}
                        fullWidth
                        onClick={handleClose}
                        color="primary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                    <Button
                        endIcon={<LogoutIcon />}
                        fullWidth
                        onClick={handleConfirmLogout}
                        color="primary"
                        variant="contained"
                        autoFocus
                        sx={{ color: 'white' }}
                    >
                        Logout
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}
