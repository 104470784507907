import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@mui/material";
import { styled, useMediaQuery } from "@mui/system";
import ProductModal from "./ProductModal";
import { useDispatch, useSelector } from "react-redux";
import DiscountLabel from "./DiscountLabel";
import {
  addItemToLocalCart,
  addItemToServerCart,
  fetchLocalCart,
  fetchServerCart,
} from "../../redux/slices/cartSlice";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import OfferModal from "./OfferModal";
import QuantitySelector from "../cart/QuantitySelector";

const StyledProductCard = styled(Card)(({ theme, listview }) => ({
  maxWidth: listview ? "100%" : 300,
  height: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: listview ? "row" : "column",
  alignItems: listview ? "flex-start" : "stretch",
  gap: listview ? theme.spacing(2) : 0,
  boxShadow: listview ? theme.shadows[4] : "none",
  borderRadius: theme.shape.borderRadius,
  transition: "transform 0.3s",
  "&:hover .productImg": {
    transform: "scale(1.05)",
  },
  // [theme.breakpoints.down("sm")]: {
  //   maxWidth: 200,
  // },
}));

const StyledProductImage = styled(CardMedia)(({ theme, listview }) => ({
  width: listview ? 200 : "100%",
  height: listview ? "100%" : 200,
  borderRadius: 5,
  transition: "transform 0.3s",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    height: listview ? "100%" : 100,
    width: listview ? 150 : "100%"

  },
}));

const ProductCard = ({ product, listview = false }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [detailOpen, setDetailOpen] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { isAuthenticated } = useSelector((state) => state.auth);
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const cartItems = isAuthenticated ? serverCart.products : localCart.products;
  const isProductInCart = cartItems.some(
    (item) => item.product.id === product?.id
  );

  const originalPrice = Math.round(Number(product?.price) || 0);
  const discountedPrice = Math.round(
    Number(product?.discounted_price) || 0
  );
  const offer = product?.offer_details;
  let offerPrice = 0;
  if (offer) {
    offerPrice =
      offer?.discount_type === "FLAT"
        ? Math.max(0, discountedPrice - Math.round(offer.discount_value))
        : Math.max(
          0,
          Math.round(discountedPrice * (1 - offer.discount_value / 100))
        );
  }

  const discountPercentage = Math.round(
    ((originalPrice - discountedPrice) / originalPrice) * 100
  );

  const handleAddItem = async () => {
    setIsLoading(true);
    try {
      if (isAuthenticated) {
        await dispatch(addItemToServerCart(product)).unwrap();
        dispatch(fetchServerCart());
      } else {
        dispatch(addItemToLocalCart(product));
        dispatch(fetchLocalCart());
      }
    } catch (error) {
      console.error("Error adding item to cart:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDetailModal = () => {
    setModalOpen((prev) => !prev);
  };

  return (
    <StyledProductCard listview={listview}>
      <Box sx={{ position: "relative", height: listview ? "100%" : "auto", overflow: "hidden", borderRadius: 2 }}>
        <StyledProductImage
          listview={listview}
          onClick={handleDetailModal}
          className="productImg"
          component="img"
          image={product?.image || `https://via.placeholder.com/150`}
          alt={product?.name || "Product"}
        />
        <DiscountLabel discount={discountPercentage} />
      </Box>
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          p: listview ? 1 : 0,

        }}
      >
        <Box>

          <Typography sx={{ cursor: "pointer", "&:hover": { color: "red" } }} onClick={handleDetailModal} gutterBottom variant="h7" fontWeight={"500"}>{product?.name}</Typography>
          {
            listview && <Typography variant="body2" color="text.secondary">{product.description}</Typography>

          }


          <Typography mt={0.5} variant="body2" textTransform={"uppercase"} fontSize={"0.7rem"} fontWeight={"bold"} color={product.is_in_stock ? "secondary" : "red"}>{product.is_in_stock ? "In Stock" : "Out Of Stock"}</Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              mt: 1,
            }}
          >
            <Typography
              variant={isSmallScreen ? "body2" : "body1"}
              sx={{
                textDecoration: product?.discounted_price
                  ? "line-through"
                  : "none",
                color: product?.discounted_price ? "gray" : "black",
              }}
            >
              ₹{product?.price}
            </Typography>
            {product?.discounted_price && (
              <Typography variant={isSmallScreen ? "body2" : "body1"} color="secondary" fontWeight={"bold"}>
                ₹{product.discounted_price}
              </Typography>
            )}
          </Box>
        </Box>
        {/* {offer && (
          <Box sx={{ width: "100%" }}>
            <Button
              fullWidth
              onClick={(event) => setDetailOpen(event.currentTarget)}
              size="small"
              variant="outlined"
              color="warning"
              sx={{
                my: 1,
                textTransform: "none",
                background:
                  "linear-gradient(to right, white, #FFCDD2)",
                "&:hover": {
                  background:
                    "linear-gradient(to right, white, #FFCDD2)",
                },
              }}
            >
              <ShoppingBagIcon
                sx={{ fontSize: "1rem", mb: 0.3, mr: 1 }}
              />
              Get it for ₹{offerPrice}
            </Button>
            <OfferModal
              offer={product?.offer_details}
              detailOpen={detailOpen}
              handleClose={() => setDetailOpen(null)}
            />
          </Box>
        )} */}
        <Box>
          {isProductInCart ? (
            <QuantitySelector
              item={cartItems.find(
                (item) => item.product.id === product.id
              )}
            />
          ) : (
            <Button
              onClick={handleAddItem}
              disabled={isLoading || !product.is_in_stock}
              variant="contained"
              color="primary"
              size="small"
              sx={{ mt: 1, width: "100%", color: "white" }}
            >
              {
                product.is_in_stock ? "Add to Cart": "Out Of Stock"
              }
              
            </Button>
          )}
        </Box>
      </CardContent>
      <ProductModal
        open={modalOpen}
        handleClose={handleDetailModal}
        product={product}
      />
    </StyledProductCard>
  );
};

export default ProductCard;
