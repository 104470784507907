import React, { useEffect, useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import couponService from '../../services/cartService';
import CouponItem from './CouponItem';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 2,
  overflow: 'hidden',
};

export default function CouponModal({ open, handleClose, onSelectCoupon }) {
  const [coupons, setCoupons] = useState([]);
  const [filteredCoupons, setFilteredCoupons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const { selectedCoupon: selectedCouponRedux } = useSelector((state) => state.order);
  const [selectedCoupon, setSelectedCoupon] = useState(selectedCouponRedux || null);
  

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const response = await couponService.getAllCoupons();
        setCoupons(response.data);
        setFilteredCoupons(response.data);
      } catch (err) {
        console.error(err);
        setError('Failed to load coupons');
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchCoupons();
    }
  }, [open]);

  useEffect(() => {
    const filtered = coupons.filter((coupon) =>
      coupon.coupon_code.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredCoupons(filtered);
  }, [searchTerm, coupons]);

  const handleCouponSelect = (coupon) => {
    if (selectedCoupon?.id === coupon.id) {
      setSelectedCoupon(null); 
      onSelectCoupon(null);
    } else {
      setSelectedCoupon(coupon); 
    }
  };
  const handleApplyCoupon = () => {
    if (selectedCoupon) {
      onSelectCoupon(selectedCoupon);
      handleClose();
    }
  };

 

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" sx={{ px: 2, pt: 2 }}>
            Select a Coupon
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Box sx={{ px: 2, pb: 1 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search for a coupon..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Box>
          <Divider />
          <Box sx={{ height: '300px', overflowY: 'scroll', p: 2 }}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress color="primary" />
              </Box>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : filteredCoupons.length > 0 ? (
              filteredCoupons.map((coupon) => (
                <CouponItem
                  key={coupon.id}
                  coupon={coupon}
                  selectedCoupon={selectedCoupon}
                  onSelect={handleCouponSelect}
                />
              ))
            ) : (
              <Typography>No coupons available.</Typography>
            )}
          </Box>
          {selectedCoupon && (
            <Box sx={{ px: 2, py: 1, display: 'flex', justifyContent: 'center', gap: 2, alignItems:"center" }}>
              <Button  variant="outlined" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
              <Button  variant="contained" color="primary" sx={{color:"white"}} onClick={handleApplyCoupon}>
                Apply Coupon
              </Button>
            </Box>
          )}
        </Box>
      </Modal>

     
      
    </>
  );
}
