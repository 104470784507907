import React from 'react';
import { Box, Chip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

export default function PopularCategoryChips() {
  const { category } = useParams();
  const navigate = useNavigate();

  const handleCategoryChange = (newCategory) => {
    navigate(`/shop/${newCategory}`);
  };

  const categories = [
    { name: 'All', value: '' },
    { name: 'Indian', value: 'indian' },
    { name: 'Chinese', value: 'chinese' },
    { name: 'Soup Veggies', value: 'soup veggies' },
    { name: 'Stir-fry Veggies', value: 'stir-fry veggies' },
    { name: 'Salad Veggies', value: 'salad veggies' },
    { name: 'Tropical Veggies', value: 'tropical' },
    { name: 'Crisp Veggies', value: 'crisp' },
  ];

  return (
    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center', my: 3, flexWrap: 'wrap' }}>
      {categories.map(({ name, value }) => (
        <Chip
          key={value}
          label={name}
          clickable
          color={category === value || (!category && value === '') ? 'primary' : 'default'}
          onClick={() => handleCategoryChange(value)}
          variant={category === value || (!category && value === '') ? 'filled' : 'outlined'}
          sx={{ color: category === value || (!category && value === '') ? 'white' : 'black' }}
        />
      ))}
    </Box>
  );
}
