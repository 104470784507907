import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Home from './pages/Home';
import Shop from './pages/Shop';
import Wishlist from './pages/Wishlist';
import ProductFullDetails from './pages/ProductFullDetails';
import AboutUs from './pages/AboutUs';
import Cart from './pages/Cart';
import ContactUs from './pages/ContactUs';
import PrivateRoute from './components/core/PrivateRoute';
import Account from './pages/Account';
import Blog from './pages/Blog';
import BlogDetail from './pages/BlogDetail';
import SearchResults from './pages/SearchResults';
import GoogleCallback from './components/google-auth/GoogleCallback';
import OrderConfirmed from './pages/OrderConfirmed';
import OrderConfirmation from './pages/OrderConfirmation';
import InstallPWA from './pages/install';
import CheckoutSteps from './pages/CheckoutSteps';
import FAQPage from './pages/FAQ';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import ShippingAndReturnPolicy from './pages/ShippingAndReturnPolicy';

const AppRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Home />} />

            <Route path="account/signup" element={<Signup />} />
            <Route path="account/login" element={<Login />} />
            <Route path="/shop" element={<Shop />} />
            <Route path="/shop/:category" element={<Shop />} />

            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route
                path="/shipping-and-return-policy"
                element={<ShippingAndReturnPolicy />}
            />
            <Route
                path="/terms-and-conditions"
                element={<TermsAndConditions />}
            />
            <Route
                path="/product/:productName"
                element={<ProductFullDetails />}
            />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
            <Route path="/search" element={<SearchResults />} />
            <Route path="/auth/google/callback" element={<GoogleCallback />} />
            <Route path="/install" element={<InstallPWA />} />
            <Route path="/cart" element={<Cart />} />
            {/* <Route path="/favorite" element={<Wishlist />} /> */}

            <Route element={<PrivateRoute />}>
                <Route path="account/:tabName?" element={<Account />} />
                {/* <Route path="/checkout" element={<Checkout />} /> */}
                <Route path="/checkout/:step" element={<CheckoutSteps />} />
                <Route path="/order-confirmed" element={<OrderConfirmed />} />
                <Route
                    path="/order-confirmation"
                    element={<OrderConfirmation />}
                />
            </Route>
        </Routes>
    );
};

export default AppRoutes;
