import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import useAllData from '../../hooks/useAllData';
import api from '../../apiCollection';
import ProductCard from '../product/ProductCard';

export default function SimilarProducts() {

    const { loading, error, allData } = useAllData(api.products.getAll);

    if (error) {
        return <Typography color="error">{error.message}</Typography>;
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
                You may also like these products
            </Typography>
            <Grid container columnSpacing={2} rowGap={5} sx={{ my: 2 }}>
                {loading
                    ? 
                      Array.from(new Array(4)).map((_, index) => (
                          <Grid key={index} item xs={6} md={3}>
                              <Skeleton
                                  variant="rectangular"
                                  height={200}
                                  sx={{ borderRadius: 2 }}
                              />
                              <Skeleton variant="text" sx={{ mt: 1 }} />
                              <Skeleton variant="text" width="60%" />
                          </Grid>
                      ))
                    : 
                      allData.slice(0, 4).map((product) => (
                          <Grid key={product.id} item xs={6} md={3}>
                              <ProductCard product={product} />
                          </Grid>
                      ))}
            </Grid>
        </Box>
    );
}
