import React, { useEffect, useState, useRef } from 'react';
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import { useSelector } from 'react-redux';
import { useTheme } from "@mui/material/styles";
import { Link } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import EmptyCart from '../EmptyCart';
import { CircularProgress, LinearProgress } from '@mui/material';
import CartList from './CartList';
import Confetti from 'react-confetti';

export default function CartDrawer({ toggleDrawer, drawerOpen }) {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const localCart = useSelector((state) => state.cart.localCart);
  const serverCart = useSelector((state) => state.cart.serverCart);
  const cart = isAuthenticated ? serverCart : localCart;

  const theme = useTheme();
  const [showConfetti, setShowConfetti] = useState(false);
  const prevTotalRef = useRef(cart.total);
  const confettiShownRef = useRef(false);

  const isEmpty = cart.products.length === 0;
  const freeShippingThreshold = 200;
  const progressPercentage = Math.min((cart.total / freeShippingThreshold) * 100, 100);
  useEffect(() => {
    if (
      prevTotalRef.current < freeShippingThreshold && 
      cart.total >= freeShippingThreshold &&        
      !confettiShownRef.current                      
    ) {
      setShowConfetti(true);
      confettiShownRef.current = true; 
      const timer = setTimeout(() => setShowConfetti(false), 4000);
      return () => clearTimeout(timer);
    } 
    
    
    if (cart.total < freeShippingThreshold) {
      confettiShownRef.current = false;
    }
  
    prevTotalRef.current = cart.total; 
  }, [cart.total, freeShippingThreshold]);
  
  useEffect(() => {
   
    if (drawerOpen === false) {
      setShowConfetti(false);
    }
  }, [drawerOpen]);



  return (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer}>
      <Box sx={{ width: "400px", height: "100vh", position: "relative", overflow: "hidden", display: "flex", flexDirection: "column" }}>
        {cart.status === "loading" ? (
          <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <CircularProgress size={20} />
          </Box>
        ) : (
          isEmpty ? (
            <EmptyCart onClose={toggleDrawer} />
          ) : (
            <>
              {showConfetti && <Confetti width={400} height={600} numberOfPieces={150} />}
              <Typography sx={{ ml: 2, mt: 2, fontWeight: 800 }} variant="h6" gutterBottom>My Cart</Typography>
              <IconButton sx={{ position: 'absolute', top: 8, right: 8 }} onClick={toggleDrawer}>
                <CloseIcon />
              </IconButton>
              <Box className="scroll" sx={{
                overflowY: "auto", "&::-webkit-scrollbar-thumb": {
                  background: theme.palette.primary.main
                }
              }}>
                <CartList onClose={toggleDrawer} />
              </Box>
              <Box sx={{ textAlign: "center", p: 2, bgcolor: "background.default", boxShadow: 2 }}>
                {cart.total >= freeShippingThreshold ? (
                  <Box sx={{
                    bgcolor: "#f44336",
                    mb: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    animation: "burst 0.5s ease-out"
                  }}>
                    <Typography
                      variant="caption"
                      sx={{
                        textTransform: "uppercase",
                        fontWeight: 600,
                        color: "white",
                        animation: "burst 0.5s ease-out"
                      }}
                    >
                      Congratulations! You've got free shipping
                    </Typography>
                    <LocalShippingOutlinedIcon sx={{ color: "white", fontSize: "1.2rem", ml: 1 }} />
                  </Box>
                ) : (
                  <Box sx={{ mb: 1 }}>
                    <Typography
                      variant="caption"
                      sx={{ fontWeight: 600, color: theme.palette.text.secondary }}
                    >
                      Add ₹{freeShippingThreshold - cart.total} more for free shipping
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={progressPercentage}
                      sx={{
                        height: 10,
                        borderRadius: 5,
                        mt: 1,
                        backgroundColor: "#ffcdd2",
                        "& .MuiLinearProgress-bar": {
                          background: "linear-gradient(to right, red, yellow)",
                        },
                      }}
                    />
                  </Box>
                )}
                <Typography gutterBottom variant='h6' sx={{ fontWeight: "800" }}>
                  Total : <span style={{ color: theme.palette.primary.main }}>₹ {cart.total}</span>
                </Typography>
                <Link to="/checkout/cart">
                  <Button onClick={toggleDrawer} variant='contained' color="primary" fullWidth sx={{ color: "white" }}>
                    View Cart
                  </Button>
                </Link>
              </Box>
            </>
          )
        )}
      </Box>

      {/* Add animation styles here */}
      <style jsx>{`
        @keyframes burst {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          50% {
            transform: scale(1.2);
            opacity: 0.8;
          }
          100% {
            transform: scale(1);
            opacity: 1;
          }
        }
      `}</style>
    </Drawer>
  );
}
