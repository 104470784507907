import React, { useEffect, useState, useCallback } from "react";
import {
  Container,
  Grid,
  Box,
  Button,
  Typography,
  useMediaQuery,
  Divider,
  Skeleton,
} from "@mui/material";
import ProductCard from "../components/product/ProductCard";
import Header from "../components/shop/SortHeader";
import { useTheme } from "@mui/material/styles";
import Filters from "../components/shop/Filters";
import PopularCategoryButtons from "../components/shop/PopularCategoryButtons";
import { useParams } from "react-router-dom";
import { loadProducts, resetProducts } from "../redux/slices/productSlice";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../components/core/Loading";

export default function Shop() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { category } = useParams();

  const [view, setView] = useState("grid");
  const [sortOption, setSortOption] = useState("priceLowHigh");
  const [selectedFilters, setSelectedFilters] = useState({
    priceRange: [0, 500],
    inStock: false,
    onSale: false,
    selectedBrands: [],
  });

  const { items: products, page, hasMore, status, error } = useSelector(
    (state) => state.products
  );

  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(resetProducts());
    dispatch(loadProducts({ page: 1, limit: 10 }));
  }, [dispatch]);

  // Infinite scrolling logic
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight + 100 &&
        hasMore &&
        status !== "loading"
      ) {
        dispatch(loadProducts({ page, limit: 10 }));
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [dispatch, hasMore, page, status]);

  // Handle filtering and sorting
  const handleDataProcessing = useCallback(() => {
    let filtered = products.filter((product) => {
      const price = product.discounted_price || product.price;

      if (
        price < selectedFilters.priceRange[0] ||
        price > selectedFilters.priceRange[1]
      ) {
        return false;
      }
      if (selectedFilters.inStock && !product.is_in_stock) {
        return false;
      }
      if (selectedFilters.onSale && !product.offer_price) {
        return false;
      }
      if (
        selectedFilters.selectedBrands.length &&
        !selectedFilters.selectedBrands.includes(product.brand)
      ) {
        return false;
      }

      // category filter
      if (category && !product.tags.includes(category)) {
        return false;
      }

      return true;
    });

    // sorting logic
    if (sortOption === "priceLowHigh") {
      filtered = filtered.sort((a, b) => a.price - b.price);
    } else {
      filtered = filtered.sort((a, b) => b.price - a.price);
    }

    return filtered;
  }, [products, selectedFilters, category, sortOption]);

  const processedData = handleDataProcessing();

  if (status === "loading" && page === 1) return <Loading />;

  if (status === "failed") return <Typography>{error}</Typography>;

  return (
    <Box>
      <Container maxWidth="lg">
        <PopularCategoryButtons />
        <Divider flexItem />
        <Grid container spacing={2}>
          {!isMediumScreen && (
            <Grid item xs={12} md={2.5}>
              <Box
                sx={{
                  position: "sticky",
                  top: "20px",
                  maxHeight: "100vh",
                  borderRight: "1px solid #ddd",
                  py: 2,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <Filters onApplyFilters={setSelectedFilters} />
              </Box>
            </Grid>
          )}
          <Grid item xs={12} md={9.5}>
            <Box sx={{ bgcolor: theme.palette.background.default, py: 2 }}>
              <Header
                view={view}
                setView={setView}
                sortOption={sortOption}
                setSortOption={setSortOption}
                setSelectedFilters={setSelectedFilters}
              />
            </Box>

            <Box sx={{ minHeight: "100vh" }}>
              {status === "loading" ? (
                // skeleton
                <Grid container columnSpacing={2} rowGap={2}>
                  {[...Array(10)].map((_, index) => (
                    <Grid
                      item
                      xs={view === "grid" ? 6 : 12}
                      md={view === "grid" ? 3 : 12}
                      key={index}
                    >
                      <Skeleton variant="rectangular" width="100%" height={250} />
                      <Skeleton variant="text" width="60%" />
                      <Skeleton variant="text" width="40%" />
                    </Grid>
                  ))}
                </Grid>
              ) : processedData.length > 0 ? (
                <>
                  <Grid container columnSpacing={2} rowGap={2}>
                    {processedData.map((product) => (
                      <Grid
                        item
                        xs={view === "grid" ? 6 : 12}
                        md={view === "grid" ? 3 : 12}
                        key={product.id}
                      >
                        <ProductCard product={product} listview={view === "list" ? true : undefined} />
                      </Grid>
                    ))}

                  </Grid>
                    {!hasMore && (
                      <Typography my={3} align="center">No more products to load.</Typography>
                    )}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "50vh",
                    textAlign: "center",
                    gap: 2,
                  }}
                >
                  <Typography variant="h6" color="textSecondary">
                    No products found matching your criteria.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ color: "white" }}
                    onClick={() => {
                      setSelectedFilters({
                        priceRange: [0, 500],
                        inStock: false,
                        onSale: false,
                        selectedBrands: [],
                      });
                    }}
                  >
                    Reset Filters
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
