// rootReducer.js
import { combineReducers } from '@reduxjs/toolkit';
import cartReducer from './slices/cartSlice';
import wishlistReducer from './slices/wishlistSlice';
import authReducer, { logout } from './slices/authSlice';
import addressReducer from './slices/addressSlice';
import orderReducer from './slices/orderSlice'
import productReducer from './slices/productSlice'

const appReducer = combineReducers({
  cart: cartReducer,
  wishlist: wishlistReducer,
  auth: authReducer,
  address: addressReducer,
  order: orderReducer,
  products:productReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
