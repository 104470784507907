import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import LocalShippingOutlined from '@mui/icons-material/LocalShippingOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { addItemToLocalCart, addItemToServerCart } from '../../redux/slices/cartSlice';
import { addItemToLocalWishlist, addItemToServerWishlist } from '../../redux/slices/wishlistSlice';
import { useTheme } from '@mui/material/styles';
import EastRoundedIcon from '@mui/icons-material/EastRounded';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { CircularProgress } from '@mui/material';


export default function ProductDetail({ product }) {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleAddToWishlist = async () => {
        if (isAuthenticated) {
            await dispatch(addItemToServerWishlist(product));
        } else {
            dispatch(addItemToLocalWishlist());
        }
    };

    const handleAddToCart = async () => {
        if (isAuthenticated) {
            setIsLoading(true);
            await dispatch(addItemToServerCart(product));
            setIsLoading(false);
        } else {
            dispatch(addItemToLocalCart(product))
        }
    };

    const [quantity, setQuantity] = useState(1);


    const location = useLocation();

    const isProductPage = location.pathname.split('/')[1] === 'product';
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: 2,
                height: '100%',
                width: '100%',
            }}
        >
            <Box
                sx={{
                    width: { xs: '100%', md: '800px' },
                    height: '100%',
                    borderRadius: '0px',
                }}
            >
                <img
                    style={{
                        height: '100%',
                        width: '100%',
                        borderRadius: '0.3rem',
                        objectFit: 'cover',
                    }}
                    src={product.image}
                    alt={product.name}
                />
            </Box>

            <Box
                sx={{
                    overflow: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '0px',
                    },
                    '&:hover::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: '#ccc',
                        borderRadius: '4px',
                    },
                    '&:hover::-webkit-scrollbar-track': {
                        backgroundColor: '#f0f0f0',
                    },
                    height: '100%',
                    pr: 2,
                }}
            >
                <Box sx={{ width: '100%' }}>
                    <Typography
                        gutterBottom
                        variant="h6"
                        sx={{ fontWeight: 800 }}
                    >
                        {product.name}
                    </Typography>

                    <Typography
                        sx={{ fontWeight: 500, color: 'text.secondary' }}
                        variant={'body2'}
                        gutterBottom
                    >
                        {product.description ||
                            'Description Not Available For This Product...'}
                    </Typography>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Typography
                            gutterBottom
                            variant="h6"
                            sx={{
                                color: product.discounted_price
                                    ? 'gray'
                                    : theme.palette.primary.main,
                                textDecoration: product.discounted_price
                                    ? 'line-through'
                                    : 'none',
                                fontWeight: 600,
                            }}
                        >
                            ₹ {product.price || '2.4'}
                        </Typography>
                        <Typography
                            gutterBottom
                            variant="h6"
                            sx={{
                                color: theme.palette.primary.main,
                                fontWeight: 600,
                            }}
                        >
                            ₹ {product.discounted_price || '2.4'}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1,
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontWeight: 700,
                                flex: 4,
                                textTransform: 'uppercase',
                            }}
                        >
                            Vendor
                        </Typography>
                        <Typography
                            variant={'body2'}
                            sx={{ fontWeight: 500, flex: 8, color: 'text.secondary' }}
                        >
                            {product.brand || 'Fresho'}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1,
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontWeight: 700,
                                flex: 4,
                                textTransform: 'uppercase',
                            }}
                        >
                            Type
                        </Typography>
                        <Typography
                            variant={'body2'}
                            sx={{ fontWeight: 500, color: 'text.secondary', flex: 8 }}
                        >
                            {product.category_id === 1 || 2
                                ? 'Vegetable'
                                : 'Fruit'}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            mt: 1,
                            gap: 2,
                        }}
                    >
                        <Typography
                            variant={'body2'}
                            sx={{
                                fontWeight: 700,
                                flex: 4,
                                textTransform: 'uppercase',
                            }}
                        >
                            Availability
                        </Typography>
                        <Typography
                            variant={'body2'}
                            sx={{ fontWeight: 500, color: 'text.secondary', flex: 8 }}
                        >
                            {product.is_in_stock
                                ? 'In Stock'
                                : 'Out of Stock'}
                        </Typography>
                    </Box>


                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            gap: 2,
                        }}
                    >
                        <Typography
                            whiteSpace="nowrap"
                            variant="body2"
                            sx={{ flex: 4, fontWeight: 600 }}
                        >
                            Sub total :
                        </Typography>
                        <Typography
                            color="primary"
                            variant="body2"
                            sx={{ flex: 8, fontWeight: 600 }}
                        >
                            ₹{' '}
                            {product.discounted_price
                                ? (
                                    product.discounted_price * quantity
                                ).toFixed(2)
                                : (product.price * quantity).toFixed(2)}
                        </Typography>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 2,
                            gap: 2
                        }}
                    >
                        <Button
                            size="small"
                            onClick={handleAddToCart}
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                borderRadius: '1.2rem',
                                color: 'white',
                                whiteSpace: 'nowrap',
                            }}
                            variant="contained"
                            fullWidth
                        >
                            {!isLoading ? (
                                <>
                                    <Typography variant="subtitle2">
                                        Add to cart
                                    </Typography>

                                    <ShoppingBagIcon
                                        sx={{ ml: 1, fontSize: '1rem' }}
                                    />
                                </>
                            ) : (
                                <CircularProgress
                                    sx={{ color: 'white' }}
                                    size={20}
                                />
                            )}
                        </Button>

                        <Button
                            size="small"
                            fullWidth
                            disabled
                            sx={{
                                bgcolor: theme.palette.primary.main,
                                borderRadius: '1.2rem',
                                color: 'white',
                            }}
                            variant="contained"
                        >
                            Buy it Now
                        </Button>

                    </Box>



                    {!isProductPage && (
                        <Box sx={{ mt: 1 }}>
                            {' '}
                            <Link to={`/product/${product.name}`}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },
                                    }}
                                >
                                    
                                    <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 500, textTransform: "uppercase", color: "red" }}
                                    >
                                        View Details
                                    </Typography>
                                    <EastRoundedIcon fontSize="small" sx={{ color: "red" }} />
                                </Box>
                            </Link>
                        </Box>
                    )}

                    {isProductPage && (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 3,
                                my: 3,
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 3,
                                }}
                            >
                                <LocalShippingOutlined fontSize="medium" />
                                <Typography variant="subtitle1">
                                    Estimated deliver 5-7 days
                                </Typography>
                            </Box>

                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
}
