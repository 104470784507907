import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Typography, Container, Box } from '@mui/material';

const OrderConfirmed = () => {
    const navigate = useNavigate();
    const location = useLocation(); // To access the query parameters
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [error, setError] = useState(null);

    // Function to parse query params from the URL
    const getQueryParams = () => {
        const params = new URLSearchParams(location.search);
        const status = params.get('code');
        const checksum = params.get('checksum');
        // You might want to validate the checksum as well (not covered in this example)
        return { status, checksum };


        
    };
    useEffect(() => {
        // Clear the history stack and replace with the Order Confirmed page
        navigate('/order-confirmed', { replace: true });
      }, [navigate]);
   

    useEffect(() => {
        const { status, checksum } = getQueryParams();

        if (status) {
            if (status === 'PAYMENT_SUCCESS') {
                setPaymentStatus('Payment Successful!');
            } else if (status === 'PAYMENT_PENDING') {
                setPaymentStatus('Payment is still pending.');
            } else {
                setError('Payment failed or unknown status.');
            }
        } else {
            setError('No payment status found.');
        }
    }, [location.search]);

    const handleRedirect = () => {
        navigate('/account/orders');
    };

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
                zIndex: 2000,
                bgcolor: 'white',
            }}
        >
            <Container
                maxWidth="lg"
                sx={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                textAlign="center"
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                    }}
                >
                    <img
                        src="/green-check.webp"
                        width={200}
                        alt="order confirm green tick"
                    />
                    <Typography sx={{ mt: 2 }} variant="h4">
                        {paymentStatus ? paymentStatus : 'Loading...'}
                    </Typography>
                    {error && (
                        <Typography sx={{ mt: 2 }} variant="h6" color="error">
                            {error}
                        </Typography>
                    )}
                    <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                        Thank you for your purchase.{' '}
                        {paymentStatus &&
                        paymentStatus === 'Payment Successful!'
                            ? 'Your order has been successfully placed.'
                            : 'Please check your payment status or try again.'}
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRedirect}
                        sx={{ color: 'white', borderRadius: '1.2rem', mt: 2 }}
                    >
                        Go to Order History
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default OrderConfirmed;
