import React, { useCallback, useState } from "react";
import { Box, debounce, IconButton, TextField, useTheme } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { updateItemQuantityInLocalCart, updateItemQuantityInServerCart, removeItemFromServerCart, removeItemFromLocalCart } from "../../redux/slices/cartSlice";

const QuantitySelector = ({ item, min = 1, max = 100, initial = 1, onChange }) => {
  const [quantity, setQuantity] = useState(item.quantity || initial);
  const theme = useTheme();
  const dispatch = useDispatch();

  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const debouncedUpdateQuantity = useCallback(
    debounce((newQuantity) => {
      if (newQuantity >= min) {
        if (isAuthenticated) {
          dispatch(updateItemQuantityInServerCart({
            itemId: item?.product.id,
            quantity: newQuantity,
            product_unit: item?.product.product_unit
          }));
        } else {
          dispatch(updateItemQuantityInLocalCart({
            itemId: item?.product.id,
            quantity: newQuantity,
            product_unit: item?.product.product_unit
          }));
        }
      }
    }, 500),
    [dispatch, item?.product.id, item?.product.product_unit, isAuthenticated]
  );

  const handleIncrease = () => {
    if (quantity < max) {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onChange && onChange(newQuantity);

      debouncedUpdateQuantity(newQuantity);
    }
  };

  const handleDecrease = () => {
    if (quantity > min) {
      const newQuantity = quantity - 1;
      setQuantity(newQuantity);
      onChange && onChange(newQuantity);

      debouncedUpdateQuantity(newQuantity);
    } else if (quantity === min) {
      // Remove item from cart
      if (isAuthenticated) {
        dispatch(removeItemFromServerCart({ productId: item?.product.id }));
      } else {
        dispatch(removeItemFromLocalCart({ productId: item?.product.id }));
      }
      onChange && onChange(0);
    }
  };

  const handleInputChange = (e) => {
    let value = parseInt(e.target.value, 10);
    if (isNaN(value)) value = min;
    if (value >= min && value <= max) {
      setQuantity(value);
      onChange && onChange(value);
    }
  };

  return (
    <Box display="flex" alignItems="center" gap={1} justifyContent="center">
      <IconButton
        color="primary"
        onClick={handleDecrease}
        disabled={quantity < min}
        size="small"
        sx={{
          padding: "4px",
          backgroundColor: theme.palette.primary.main,
          "&:hover": { bgcolor: theme.palette.primary.dark },
          color: "white",
        }}
      >
        <Remove fontSize="small" />
      </IconButton>
      <TextField
        size="small"
        value={quantity}
        onChange={handleInputChange}
        inputProps={{
          style: { textAlign: "center", width: "40px", fontSize: "0.875rem" },
          min,
          max,
        }}
        style={{ width: "50px" }}
      />
      <IconButton
        color="primary"
        onClick={handleIncrease}
        disabled={quantity >= max}
        size="small"
        sx={{
          padding: "4px",
          color: "white",
          backgroundColor: theme.palette.primary.main,
          "&:hover": { bgcolor: theme.palette.primary.dark },
        }}
      >
        <Add fontSize="small" />
      </IconButton>
    </Box>
  );
};

export default QuantitySelector;
