import { React } from 'react';
import Modal from "@mui/material/Modal"
import IconButton from "@mui/material/IconButton"
import Box from "@mui/material/Box"
import CloseIcon from '@mui/icons-material/Close';
import ProductDetail from './ProductDetail';

export default function ProductModal({ open, handleClose, product }) {

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={{
        overflow: "hidden",
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs: "90%", md: "50%" },
        height: { xs: "80%", md: "60%" },
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 1,
        p: 2

      }}>
        <IconButton
          sx={{ position: 'absolute', top: 8, right: 8, boxShadow: 2, bgcolor: "background.default" }}
          onClick={handleClose}
        >
          <CloseIcon fontSize='small' />
        </IconButton>

        <ProductDetail product={product} onClose={handleClose} />
      </Box>



    </Modal>
  );
}
