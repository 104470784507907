import React, { useState, useEffect } from 'react';
import { Button, Typography, Box, Container } from '@mui/material';

function InstallPWA() {
    const [isInstallable, setIsInstallable] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isInstalled, setIsInstalled] = useState(false);
    const [isIOS, setIsIOS] = useState(false);

    useEffect(() => {
        
        const userAgent = window.navigator.userAgent.toLowerCase();
        const isIosDevice = /iphone|ipad|ipod/.test(userAgent);
        setIsIOS(isIosDevice);

        
        const checkIfInstalled = () => {
            if (isIosDevice) {
                setIsInstalled(window.navigator.standalone);
            } else {
                const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
                setIsInstalled(isStandalone);
            }
        };

        checkIfInstalled();

        
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
            setIsInstallable(true);
        };

        if (!isIosDevice) {
            window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        }

        return () => {
            if (!isIosDevice) {
                window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
            }
        };
    }, []);

    const handleInstallClick = async () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('User accepted the PWA install prompt');
            } else {
                console.log('User dismissed the PWA install prompt');
            }
            setDeferredPrompt(null);
            setIsInstallable(false);
        }
    };

    return (
        <Container maxWidth="sm">
            <Box
                sx={{
                    textAlign: 'center',
                    padding: '2rem',
                    borderRadius: '10px',
                    height: '400px',
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ fontWeight: 600 }}
                >
                    Install Our App
                </Typography>
                <Typography variant="body1" paragraph>
                    Get quick access to our app by installing it on your device!
                </Typography>
                {isInstalled ? (
                    <Typography variant="body2" color="textSecondary">
                        App is Already Installed!
                    </Typography>
                ) : isIOS ? (
                    <Typography variant="body2" color="textSecondary">
                        On iOS, tap the Share button below and select "Add to Home Screen" to install the app.
                    </Typography>
                ) : isInstallable ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleInstallClick}
                        sx={{ mt: 2, color: 'white' }}
                    >
                        Install App
                    </Button>
                ) : (
                    <Typography variant="body2" color="textSecondary">
                        App cannot be installed right now.
                    </Typography>
                )}
            </Box>
        </Container>
    );
}

export default InstallPWA;
