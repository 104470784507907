import React, { useState } from 'react';
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Drawer from "@mui/material/Drawer"
import useMediaQuery from '@mui/material/useMediaQuery';
import GridViewIcon from '@mui/icons-material/GridView';
import ViewListIcon from '@mui/icons-material/ViewList';
import FilterListIcon from '@mui/icons-material/FilterList';
import { useTheme } from '@mui/material/styles';
import Filters from './Filters';

const SortHeader = ({ view, setView, sortOption, setSortOption, setSelectedFilters }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Box sx={{
      display: 'flex', alignItems: 'center', justifyContent: 'space-between',
       borderRadius: '5px'
    }}>
      <Box sx={{ display: 'flex', alignItems: 'center', borderRadius: "0.4rem", border: "0.1px solid lightGray" }}>
        <Button
          onClick={() => handleViewChange('grid')}
          variant={view === 'grid' ? 'contained' : 'outlined'}
          sx={{ minWidth: 40, backgroundColor: view === 'grid' ? theme.palette.primary.main : undefined, border: "none", p: 1, color: view === "grid" ? "white" : theme.palette.primary.main }}
        >
          <GridViewIcon sx={{ fontSize: "1.2rem" }} />
        </Button>
        <Button
          onClick={() => handleViewChange('list')}
          variant={view === 'list' ? 'contained' : 'outlined'}
          sx={{ minWidth: 40, backgroundColor: view === 'list' ? theme.palette.primary.main : undefined, border: "none", p: 1, color: view === "list" ? "white" : theme.palette.primary.main }}
        >
          <ViewListIcon sx={{ fontSize: "1.2rem" }} />
        </Button>
      </Box>

    
      <FormControl
        variant="outlined"
        size="small"
        sx={{
          minWidth: isSmallScreen ? 100 : 120,
        }}
      >
        <InputLabel>Sort</InputLabel>
        <Select
          defaultValue="priceLowHigh"
          value={sortOption}
          onChange={handleSortChange}
          label="Sort"
        >
          <MenuItem disabled value="bestSelling">Best selling</MenuItem>
          <MenuItem value="priceLowHigh">Price: Low to High</MenuItem>
          <MenuItem value="priceHighLow">Price: High to Low</MenuItem>
          <MenuItem disabled value="newest">Newest</MenuItem>
        </Select>
      </FormControl>
    
      


      {isSmallScreen && (
        <>
          <IconButton onClick={toggleDrawer(true)}>
            <FilterListIcon />
          </IconButton>
          <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
            <Box sx={{ p: 2, width: 300 }}>
              <Filters onApplyFilters={setSelectedFilters} />
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default SortHeader;
