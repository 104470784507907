import React, { useEffect, useState } from 'react';

import Testimonials from '../components/home/Testimonials';
import SpecialProducts from '../components/home/SpecialProducts';
import LatestNews from '../components/home/LatestNews';
import Loading from '../components/core/Loading';
import OurProducts from '../components/home/OurProducts';
import NewProducts from '../components/home/NewProducts';
import BestSeller from '../components/home/BestSeller';
import FarmToHomeBanner from '../components/home/FarmToHomeBanner';
import { Typography, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import InstallApp from '../components/home/InstallApp';
import { useDispatch, useSelector } from 'react-redux';
import HeroSlider from '../components/home/HeroSlider';
import PopularCategoryFilter from '../components/home/PopularCategoryFilter';
import FreshFromOurFarmBanner from '../components/home/FreshFromOurFarmBanner';
import DealsOfTheDay from '../components/home/DealsOfTheDay';
import { loadProducts } from '../redux/slices/productSlice';
import { mergeCart } from '../redux/slices/cartSlice';

export default function Home() {
  const { items: products, error, status: productStatus } = useSelector(
    (state) => state.products
  );

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { isAuthenticated } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = () => {
    setModalOpen(false);
    localStorage.setItem('hasSeenModal', 'true'); 
  };

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(mergeCart());
    }
  }, [isAuthenticated, dispatch]);

  useEffect(() => {
    dispatch(loadProducts({ page: 1, limit: 10 }));
  }, [dispatch]);

  useEffect(() => {
    const hasSeenModal = localStorage.getItem('hasSeenModal');
    if (!hasSeenModal) {
      setModalOpen(true); 
    }
  }, []);

  if (productStatus === 'loading') return <Loading />;

  if (productStatus === 'failed')
    return <Typography variant="h3">{error}</Typography>;

  return (
    <>
      {/* Modal for warning */}
      <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>Website Under Development ⚠️</DialogTitle>
        <DialogContent>
          <Typography>
            This website is currently under development. There may be bugs, and
            certain features might not work as expected. Please do not make any
            payments at this time.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="warning" variant="contained">
            Understood
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Home content */}
      <HeroSlider />
      <PopularCategoryFilter />
      <DealsOfTheDay />
      <NewProducts allProducts={products} />
      <BestSeller allProducts={products} />
      {!isSmallScreen && <FarmToHomeBanner />}
      <OurProducts allProducts={products} />
      <FreshFromOurFarmBanner />
      <SpecialProducts specialProducts={products} />
      <Testimonials />
      <LatestNews />
      <InstallApp />
    </>
  );
}
