import React from 'react';
import { Box, Typography, Avatar, Paper, Container } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules'; // Import the creative effect module
import { useTheme } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star'; // Star icon from MUI
import SectionTitle from '../core/SectionTitle';

// Mock data
const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    position: 'CEO, Company ABC',
    image: 'https://images.ctfassets.net/h6goo9gw1hh6/2sNZtFAWOdP1lmQ33VwRN3/24e953b920a9cd0ff2e1d587742a2472/1-intro-photo-final.jpg?w=1200&h=992&fl=progressive&q=70&fm=jpg',
    testimonial:
      'This service is absolutely amazing! It helped our business grow by leaps and bounds. Highly recommended!',
    rating: 5, // Add rating field
  },
  {
    id: 2,
    name: 'Jane Smith',
    position: 'Marketing Head, XYZ Ltd.',
    image: 'https://plus.unsplash.com/premium_photo-1689977968861-9c91dbb16049?fm=jpg&q=60&w=3000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MXx8cHJvZmlsZSUyMHBpY3R1cmV8ZW58MHx8MHx8fDA%3D',
    testimonial:
      'The customer support is phenomenal, and the product quality is top-notch. We saw great results!',
    rating: 4,
  },
  {
    id: 3,
    name: 'Michael Johnson',
    position: 'CTO, Tech Innovators',
    image: 'https://t3.ftcdn.net/jpg/06/07/84/82/240_F_607848231_w5iFN4tMmtI2woJjMh7Q8mGvgARnzHgQ.jpg',
    testimonial:
      'A game-changer in our industry. The platform’s ease of use is exceptional, and the team is brilliant.',
    rating: 5,
  },
  {
    id: 4,
    name: 'Michael Johnson',
    position: 'CTO, Tech Innovators',
    image: 'https://t3.ftcdn.net/jpg/06/07/84/82/240_F_607848231_w5iFN4tMmtI2woJjMh7Q8mGvgARnzHgQ.jpg',
    testimonial:
      'A game-changer in our industry. The platform’s ease of use is exceptional, and the team is brilliant.',
    rating: 5,
  },
  {
    id: 5,
    name: 'Michael Johnson',
    position: 'CTO, Tech Innovators',
    image: 'https://t3.ftcdn.net/jpg/06/07/84/82/240_F_607848231_w5iFN4tMmtI2woJjMh7Q8mGvgARnzHgQ.jpg',
    testimonial:
      'A game-changer in our industry. The platform’s ease of use is exceptional, and the team is brilliant.',
    rating: 5,
  },
  {
    id: 6,
    name: 'Michael Johnson',
    position: 'CTO, Tech Innovators',
    image: 'https://t3.ftcdn.net/jpg/06/07/84/82/240_F_607848231_w5iFN4tMmtI2woJjMh7Q8mGvgARnzHgQ.jpg',
    testimonial:
      'A game-changer in our industry. The platform’s ease of use is exceptional, and the team is brilliant.',
    rating: 5,
  },
  {
    id: 7,
    name: 'Michael Johnson',
    position: 'CTO, Tech Innovators',
    image: 'https://t3.ftcdn.net/jpg/06/07/84/82/240_F_607848231_w5iFN4tMmtI2woJjMh7Q8mGvgARnzHgQ.jpg',
    testimonial:
      'A game-changer in our industry. The platform’s ease of use is exceptional, and the team is brilliant.',
    rating: 5,
  },
];

const Testimonials = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        py: 5,
        background: `url(https://res.cloudinary.com/dbi8wjrcl/image/upload/v1732275803/vegingo/Untitled_design_25_pb2qp5.png)`,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        mt: 10,
        py:5
      }}
    >
      <Container maxWidth="lg">
        <SectionTitle t1={"What Our Clients Say"}/>

        <Swiper
          modules={[Pagination]}
          spaceBetween={30}
          style={{ padding: 2 }}
          breakpoints={{
            640: {
              slidesPerView: 1, // 1 slide on small screens
            },
            960: {
              slidesPerView: 2, // 2 slides on medium screens
            },
            1280: {
              slidesPerView: 3, // 3 slides on large screens
            },
          }}
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className}" style="background-color: ${theme.palette.primary.main}; border-radius: 50%; width: 12px; height: 12px; display: inline-block; margin: 0 5px;"></span>`;
            },
          }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id} style={{ paddingTop: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Paper
                elevation={2}
                sx={{
                  p: 4,
                  position: 'relative',
                  textAlign: 'left',
                  borderRadius: '20px',
                  overflow: "visible", 
                  textAlign:"center"             
                }}
              >
                {/* Star Rating */}
                <Box sx={{ display: 'flex', mb: 2, mt: 4,justifySelf:"center"}}>
                  {[...Array(testimonial.rating)].map((_, i) => (
                    <StarIcon key={i} sx={{ color: '#D4AF37', mr: 0.5 }} />
                  ))}
                </Box>

                {/* Testimonial Text */}
                <Typography variant="body1" sx={{ mb: 3 }}>
                  "{testimonial.testimonial}"
                </Typography>

                {/* User Info */}
                <Typography variant="h6" fontWeight="bold">
                  {testimonial.name}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {testimonial.position}
                </Typography>

                {/* Avatar in bottom-right corner */}
                <Avatar
                  src={testimonial.image}
                  alt={testimonial.name}
                  sx={{
                    position: "absolute",
                    width: 100,
                    height: 100,
                    left: "50%",
                    top: 0,
                    transform: "translate(-50%, -50%)",
                    border: `2px solid ${theme.palette.background.paper}`,
                  }}
                />
              </Paper>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </Box>
  );
};

export default Testimonials;
