import React from 'react';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import CartTableItem from './CartTableItem';
import { useTheme } from '@emotion/react';

export default function CartTable() {
  const { isAuthenticated } = useSelector(state => state.auth);
  const serverCart = useSelector(state => state.cart.serverCart);
  const localCart = useSelector(state => state.cart.localCart);
  const cart = isAuthenticated ? serverCart : localCart;

  const theme = useTheme();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead sx={{bgcolor:"#E8E8E8"}}>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell align="center">Quantity</TableCell>
            <TableCell align="center">Total</TableCell>
            <TableCell align="center">Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {cart.products.map((item) => (
            <CartTableItem key={item.id} item={item} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
