import React from 'react';
import { Box, Typography } from '@mui/material';

const DiscountLabel = ({ discount }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
      }}
    >

      <Box sx={{position:"relative"}}>
      <svg
        height="140px"
        style={{ position: 'absolute', top: -40, right: -40 , filter:"drop-shadow(5px 5px 10px #000000)"}}
        viewBox="0 0 76 76"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        baseProfile="full"
        enableBackground="new 0 0 76.00 76.00"
      >
        <path
          fill="red"
          fillOpacity="1"
          strokeWidth="0.2"
          strokeLinejoin="round"
          d="M 28.5,57L 28.5,19L 47.5,19L 47.5,57L 38,47.5L 28.5,57 Z "
        />
      </svg>
      <Typography sx={{position:"absolute",top:5, right:20, fontWeight:"bold"}} variant="caption" color="common.white" >
        {discount}% OFF
      </Typography>

      </Box>
   
    </Box>
  );
};

export default DiscountLabel;
